import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import styled from "styled-components"
import BreadCrumbs from "../components/breadcrumbs"
import ArticleComponent from "../components/ArticleComponent"
import { Button } from "../components/styles/Buttons"
import "../css/style.scss"
import Footer from "../components/footer";
import Icon from "../components/Icon"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { SectionWrapper, Section, SectionTitleContainer, BottomDivider, SectionContainerGridFourCols, SectionContainerGridTwoCols, SectionBlue } from "../components/styles/Sections"
import BrandList from "../components/brandList"

const BlogPostTemplate = ({ data: { post, relatedPosts, localSearchPages: { index, store } }, location }) => {

  let rating = post.casinoInformation.rating;
  let convertedRating = parseInt(rating)
  const schema = post.seo.schema.raw;
  const canonicalUrl = post.seo ? "https://svenskaonlinecasinon.se" + post.seo.canonical : undefined;
  const ogImage = post.featuredImage.node.sourceUrl;
  const seoTitle = post.seo.title ? post.seo.title : post.title;
  let affiliateLink = post.affiliateLink.affiliateLink ? post.affiliateLink.affiliateLink.uri : undefined;

  const [showCta, setCta] = useState("hide");

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {

        if(window.pageYOffset > 400) {
          setCta("show")
        }
        else {
          setCta("hide")
        }
      }
    }
  }, [])
  
  return (
    <Layout>
      <Seo title={seoTitle} ogUrl={"https://svenskaonlinecasinon.se/" + post.slug} ogType="article" canonical={canonicalUrl} ogImage={ogImage} description={post.seo.metaDesc} schema={schema} />
      <PostHeader>
        <SectionWrapper>
          <header>
            <h1 itemProp="headline">{parse(post.title)}</h1>
          </header>
          <BrandList item={post} isReview={true} />
        </SectionWrapper>
      </PostHeader>
    
      <article className="blog-post containerSmall" id="article-content">       
        <SectionWrapper>
          <BreadCrumbs items={post.seo.breadcrumbs} modified={post.modified} transparentBackground={true} noPadding={true} />
          <BrandDetails>
            <SectionContainerGridTwoCols>
              <div className="column" itemScope itemType="http://schema.org/Review">
                <h2>Snabbfakta {post.title}</h2>
                <div itemProp="itemReviewed" itemScope itemType="https://schema.org/Game">
                  <meta itemProp="name" content={post.title} />
                  <meta itemProp="url" content={post.uri} />
                  <meta itemProp="image" content={post.featuredImage.node.sourceUrl} />
							  </div>
                <span itemProp="author" itemScope itemType="https://schema.org/Organization">
								  <meta itemProp="name" content="Svenskaonlinecasinon.se" />
							  </span>
                <table>
                  <tbody>
                    {post.casinoInformation.lanserades && (
                      <tr>
                        <td>Lanserades: </td>
                        <td>{post.casinoInformation.lanserades}</td>
                      </tr>
                    )}
                    {post.casinoInformation.hemsida && (
                      <tr>
                        <td>Hemsida: </td>
                        <td>{post.casinoInformation.hemsida}</td>
                      </tr>
                    )}
                    {post.casinoInformation.uttagstid && (
                      <tr>
                        <td>Uttagstid: </td>
                        <td>{post.casinoInformation.uttagstid}</td>
                      </tr>
                    )}
                    {post.casinoInformation.bonus && (
                      <tr>
                        <td>Bonus: </td>
                        <td>{post.casinoInformation.bonus}</td>
                      </tr>
                    )}
                    {post.casinoInformation.freespins && (
                      <tr>
                        <td>Freespins: </td>
                        <td>{post.casinoInformation.freespins}</td>
                      </tr>
                    )}
                    {post.casinoInformation.omsattningskrav && (
                      <tr>
                        <td>Omsättningskrav: </td>
                        <td>{post.casinoInformation.omsattningskrav}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Vårt betyg: </td>
                      <td itemProp="reviewRating" itemScope itemType="http://schema.org/Rating">
                      {new Array(convertedRating).fill(null).map(() => (
                        <Icon icon="star-full" size={14} />            
                      ))}
                      {new Array(rating).fill(null).map(() => (
                        rating.indexOf('.') !== -1 && (
                          <Icon icon="star-half" size={14}  />            
                        )
                      ))}
                        <meta itemProp="ratingValue" content={rating} />
                        <meta itemProp="bestRating" content="5" />
                        <meta itemProp="worstRating" content="0" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {post.prosAndCons.fordelar && (
                <div className="column">
                  <h2>För och nackdelar</h2>
                  {post.prosAndCons.fordelar.map((item, i) => (
                    <p key={i} className="pro">+ {item.fordel}</p>
                  ))}
                  {post.prosAndCons.nackdelar && post.prosAndCons.nackdelar.map((item, i) => (
                    <p key={i} className="con">- {item.nackdel}</p>
                  ))}
                </div>
              )}
            </SectionContainerGridTwoCols>
          </BrandDetails>
          {!!post.content && (
            <section>
              {parse(post.content)}
            </section>
          )}
          <Bio author={post.author.node} />
        </SectionWrapper>
        <BottomCta className={showCta}>
          <Section>
            <a href={affiliateLink} target="_blank" rel="nofollow noopener noreferrer">
              <Button className="green wide large">Till {post.title}</Button>
            </a>
          </Section>
        </BottomCta>
      </article>

      <SectionBlue>
        <SectionWrapper>
          <SectionTitleContainer hasButton>
            <h2>Populära casinon</h2>
            <Link to="/#topplista"><Button className="blue">Visa alla casinon</Button></Link>
          </SectionTitleContainer>
          <SectionContainerGridFourCols>
            {relatedPosts.nodes.filter(item => !item.id.includes(post.id)).slice(0, 4).map(item => (
              <ArticleComponent item={item} key={item.id} />
            ))}
          </SectionContainerGridFourCols>
        </SectionWrapper>
        <BottomDivider />
      </SectionBlue>
      <Footer location={location} pageUrl={post.casinoInformation.pageUrl} pageTitle={post.title} pageUrlTitle={post.casinoInformation.pageUrlTitle} />
    </Layout>
  )
}

export default BlogPostTemplate

const BottomCta = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  width: 100%;
  background: #fff;
  box-shadow: 0px -5px 15px -3px rgba(0,0,0,0.1);
  z-index: 99;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }

  a {
    max-width: 715px;
    margin: 0 auto;
    display: flex;
    text-decoration: none;
  }
`;

const PostHeader = styled.div`
  background: var(--color-blue);
  padding-bottom: 35px;

  header {
    max-width: var(--container);
    margin: 0 auto;
    text-align: center;
    color: var(--color-white);

    h1 {
      color: var(--color-white);
      margin-top: 0;
      margin-bottom: 40px;
      font-size: var(--fontSize-5);
      font-weight: var(--fontWeight-bold);
    }

    p {
      font-size: 18px;
    }
  }
`;

const BrandDetails = styled.div`
  padding: 25px 25px 15px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  margin-bottom: 50px;
  box-shadow: 0 10px 15px rgb(169 177 200 / 25%);
  border: 1px solid rgb(28 46 148 / 10%);

  &#grid {
    grid-template-columns: .55fr .45fr;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  h2 {
    font-size: 20px;
    margin-top: 0 !important;
    margin-bottom: 15px;
    padding: 0 !important;
  }

  .column {

    table {
      margin: 0;
      padding: 0;
    
      td {
        padding: 15px 0;
        color: var(--color-heading);
        font-size: 16px;

        &:last-of-type {
          font-weight: 500;
          text-align: right;
        }

        svg {
          font-size: 20px;
          color: var(--color-yellow);
        }
      }

      tr {
        border-bottom: 1px solid #f5f5f5;

        &:nth-of-type(odd) {
          background: unset;
        }

        &:last-of-type {
          border: 0;
        }
      }
    }

    .pro, .con {
      padding: 13px 20px;
      border-radius: 12px;
      font-weight: 500;
      font-size: 14px;
      margin: 0 0 8px;
    }
    .pro {
      color: #2c864b;
      background: #caf0d7;
    }

    .con {
      background: #f8cec7;
      color: #ac4131;
    }
  }
`;

export const brandQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      slug
      author {
        node {
          name
          description
          avatar {
            url
          }
          authorSocials {
            linkedin
          }
          uri
        }
      }
      affiliateLink {
        affiliateLink {
          ... on WpAffiliateTracker {
            id
            uri
          }
        }
      }
      modified(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      prosAndCons {
        fordelar {
          fordel
        }
        nackdelar {
          nackdel
        }
      }
      seo {
        title
        metaDesc
        canonical
        breadcrumbs {
          text
          url
        }
        opengraphSiteName
        opengraphType
        opengraphUrl
        opengraphImage {
          link
        }
        schema {
          raw
        }
      }
      casinoInformation {
        bonus
        bonuspercent
        description
        description2
        description3
        disclaimer
        fieldGroupName
        freespins
        hemsidaUrl
        lanserades
        loggaBakgrundsfarg
        minimumDeposit
        pageUrl
        pageUrlTitle
        rating
        terms
        uttagstid
        wagering
      }
    }
    localSearchPages {
      index
      store
    }
    relatedPosts: allWpPost(limit: 5) {
      nodes {
        title
        uri
        id
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        casinoInformation {
          bonus
          description
          description2
          description3
          disclaimer
          fieldGroupName
          freespins
          hemsidaUrl
          lanserades
          loggaBakgrundsfarg
          minimumDeposit
          pageUrl
          pageUrlTitle
          rating
          terms
          uttagstid
          wagering
        }
      }
    }  
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
